import axios from "axios";

function getP(id) {
	return new Promise((resolve, reject) => {
		const dd = (new Date()).toLocaleTimeString()
		const opt = {
			method: 'GET',
			url: `${process.env.REACT_APP_API_CLIENT}product/${id}/${dd}`,
			headers: {
				version: 'web',
				name: 'host.exp.exponent',
				company: 'grilan'
			} 
		}
		axios(opt)
			.then(r => resolve(r.data))
			.catch(reject)
	})
}

export {getP}