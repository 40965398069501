import './style.css'
import Market from './market'

function QR(props) {
    return (
        <div className='qr'>
            <img src={props.qr.src} alt={props.qr.alt} className='img-qr'/>
            <Market href={props.market.href} img={props.market.img} className='qr-mkt'/>
        </div>
    )
}

export default QR