import './style.css'
import Row from './row'

function Table(props) {
    const t = props.data.split('\n');
    const rows = t.map((el, i) => {
        const r = el.split(';');
        return <Row key={i} head={r[0]} data={r[1]} />
    });
    return (
        <table className='desc-tbl'>
            <tbody>
                {rows}
            </tbody>
        </table>
    )
}

export default Table