import './style.css'
import Footer from './footer'
import Header from './header'
import QR from './qr'
import qrList from '@data/download'

function Main(props) {
    const {qrp = true} = props;
    const {qrs = true} = props;

    const qr = qrList.map(d => 
        <QR 
            key={d.id}
            qr={d.qr}
            market={d.market}
        />
    )
    return (
        <main>
            <div>
                <Header />
            </div>
            <div>
                { props.children }
            </div>
            { qrs &&
                <div>
                    { qrp &&
                        <p id='qr-p'>Установите приложение прямо сейчас, чтобы делать заказы не выходя из дома</p>
                    }
                    <div id='qr'>
                        { qr }
                    </div>
                </div>
            }
            <div>
                <Footer />
            </div>
        </main>
    )
}
export default Main