import Main from '@cmp/main';
import './style.css'

function ErrorP({ data }) {
	let h = <h1>Ошибка</h1>;
	return (
		<Main>
			{h}
			{data && (
				<p className="error">
					{data}
					<br />
					Обратитесь к администратору
				</p>
			)}
		</Main>
	);
}

export default ErrorP;
