import './style.css'
import { useNavigate } from 'react-router-dom'

function Header(props) {
    const n = useNavigate();

    function onClick() {
        n('/');
    }

    return (
        <header>
            <div className='table-head'>
                <img src="/img/logo.png" alt="logo" onClick={onClick}/>
                <div className="call"><a href="tel:+79044089494">Позвонить</a></div>
            </div>
            {/* <div className='phone-head'>
                <h1>Овощи</h1>
                <img src="/img/apple.svg" alt="logo"/>
                <img src="/img/cherry.svg" alt="logo"/>
                <img src="/img/pumpkin.svg" alt="logo"/>
            </div> */}
        </header>
    )
}

export default Header