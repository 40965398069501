import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Main from '@page/main';
import Product from '@page/product';
import ErrorP from '@page/error';
/**
 * Маршрутизация на стороне клиента
 * https://reactdev.ru/libs/react-router.6/overview
 */
const router = createBrowserRouter([
	{
		path: '/',
		element: <Main />,
		errorElement: <ErrorP />,
	},
	{
		path: 'p/:id',
		element: <Product />,
		// loader: req => {console.log('loader1')},
		errorElement: <ErrorP />,
	},
	// {
	// 	path: 'p/:id/1',
	// 	element: <Product />,
	// 	loader: req => {console.log('loader2')},
	// 	errorElement: <ErrorP />

	// },
	// {
	// 	path: 'p/:id/1/2',
	// 	element: <Product />,
	// 	loader: req => {console.log('loader3')},
	// 	errorElement: <ErrorP />

	// },
]);

export default router;
