import './style.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getP } from '@router/fn';
import ErrorP from '@page/error';
import Load from '@cmp/load';
import Prd from './prd';

/**
 * Страница - "О нас"
 * @param {*} props
 * @returns
 */
function Product(props) {
	const { id } = useParams();
	const [data, setData] = useState(null);
	const [status, setStatus] = useState('load');
	const [err, setErr] = useState(null);

	useEffect(() => {
		getP(id)
			.then((r) => {
				setStatus('done');
				setData(r.result);
			})
			.catch((err) => {
				setStatus('err');
				setErr(err.toString());
			});
	}, [id]);

	console.log('data', data);
	if (status === 'load') return <Load />;
	if (status === 'err' && err) return <ErrorP data={err} />;
	return <Prd data={data} />;
}

export default Product;
