import Title from './title'
import Table from './table'
import Str from './str'

function format(d) {
    if (!d) return d;
    const m = d.split(/\n\s*\n/);
    return m.map((el, i) => {
        if (/^!/.test(el)) {
            return <Title key={i} data={el.slice(1)} />
        }
        if (/^\[\]/.test(el)) {
            return <Title key={i} data={el.slice(2)} />
        }
        if (/^\[t\]/.test(el)) {
            return <Table key={i} data={el.slice(3)} />
        }
        if (el.indexOf(';') > -1) {
            return <Table key={i} data={el} />
        }
        return <Str key={i} data={el} />
    })
}

export default format