import format from '@page/product/format';
import { useEffect, useState } from 'react';

function Desc({ data }) {
	const [st, setSt] = useState(true);
	const [txt, setTxt] = useState('Описание отсутствует');
	const mfr = data.mfr;
	const extra = format(data.description?.extra) ?? 'Описание отсутствует';
	const feature =
		format(data.description?.feature) ?? 'Характеристики отсутствуют';

	function onClick(v) {
		if (v === st) return;
		setSt(v);
	}

	useEffect(() => {
		setTxt(st ? extra : feature);
	}, [st, extra, feature]);

	return (
		<div id="pr-desc">
			<div id="pr-tabs">
				<p
					className={'tab ' + (st ? 'active' : 'n-active')}
					onClick={() => onClick(true)}
				>
					Описание
				</p>
				<p
					className={'tab ' + (st ? 'n-active' : 'active')}
					onClick={() => onClick(false)}
				>
					Характеристики
				</p>
			</div>
			<div id="pr-desc-wrap">
				{!st && mfr && (
					<section id="pr-mfr">
						<p>{mfr}</p>
						<hr />
					</section>
				)}
				{txt}
			</div>
		</div>
	);
}

export default Desc;
