const data = [
    {
        id: 1,
        qr: {
            src: '/img/qr-ios.svg',
            alt: 'qr-ios'
        },
        market: {
            href: 'https://apps.apple.com/ru/app/%D0%B3%D1%80%D0%B8%D0%BB%D0%B0%D0%BD/id1491490954',
            img: {
                src: '/img/ios.svg',
                alt: 'ios'
            }
        }
    },
    {
        id: 2,
        qr: {
            src: '/img/qr-google.svg',
            alt: 'qr-google'
        },
        market: {
            href: 'https://play.google.com/store/apps/details?id=com.ub.greelanclient',
            img: {
                src: '/img/google.svg',
                alt: 'google'
            }
        }
    },
    {
        id: 3,
        qr: {
            src: '/img/qr-huawei.svg',
            alt: 'qr-huawei'
        },
        market: {
            href: 'https://appgallery.huawei.com/app/C106590699',
            img: {
                src: '/img/huawei.svg',
                alt: 'huawei'
            }
        }
    },
]

export default data