import './style.css'
import mediaList from '@data/media'
import Media from './media'

function Footer(props) {
    const media = mediaList.map(m => 
        <Media 
            key={m.id}
            herf={m.href} 
            src={m.img.src}
            alt={m.img.alt}
        />
    )
    return (
        <footer>
            <div>
                <img src="/img/beet.png" alt="beet" id="img-beet"/>
                <img src="/img/carrot.png" alt="carrot" id="img-carrot"/>
                <img src="/img/kiwi.svg" alt="kiwi" id="img-kiwi"/>
            </div>
            <div id='footer'>
                <div>
                    {media}
                </div>
                <div>Copyright 2022 © Flet</div>
            </div>
        </footer>
    )
}

export default Footer