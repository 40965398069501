import './style.css';
import Main from '@cmp/main';
import { useState, useEffect } from 'react';
/**
 * Главная страница
 * @param {*} props
 * @returns
 */
function Home(props) {
	const [qrs, setQrs] = useState(window.innerWidth > 600);
	useEffect(() => {
		window.addEventListener('resize', () => {
			setQrs(window.innerWidth > 600)
		})
	}, [])
	return (
		<Main data={props.data} qrp={false} qrs={qrs}>
			<section>
				<h1>Ваш любимый сервис</h1>
				<h3>по доставке продуктов и свежих овощей</h3>
				<div className="platform">
					<a href="https://apps.apple.com/ru/app/%D0%B3%D1%80%D0%B8%D0%BB%D0%B0%D0%BD/id1491490954"><img src="img/ios.svg" alt="ios"/></a>
					<a href="https://play.google.com/store/apps/details?id=com.ub.greelanclient"><img src="img/google.svg" alt="google"/></a>
					<a href="https://appgallery.huawei.com/app/C106590699"><img src="img/huawei.svg" alt="huawei"/></a>
				</div>
			</section>
			<section className="phone">
				<img src="img/beet.png" alt="beet"/>
				<img src="img/cabbage.png" alt="cabbage"/>
				<img src="img/carrot.png" alt="carrot"/>
				<img src="img/kiwi.svg" alt="kiwi"/>
				<img src="img/onion.png" alt="onion"/>
			</section>
			<section className="bonus">
				<img src="img/component.svg" alt="component"/>
				<img src="img/100.svg" alt="100"/>
				<p>Скачайте приложение</p>
				<p>Зарегистрируйтесь</p>
				<img src="img/m-component.svg" alt="m-component"/>
				<a href="https://apps.apple.com/ru/app/%D0%B3%D1%80%D0%B8%D0%BB%D0%B0%D0%BD/id1491490954"><img src="img/ios.svg" alt="ios"/></a>
				<a href="https://play.google.com/store/apps/details?id=com.ub.greelanclient"><img src="img/google.svg" alt="google"/></a>
				<a href="https://appgallery.huawei.com/app/C106590699"><img src="img/huawei.svg" alt="huawei"/></a>
			</section>
		</ Main>
	)
}

export default Home;
