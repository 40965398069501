import Empty from '@cmp/empty';
import Main from '@cmp/main';
import { Helmet } from 'react-helmet-async';
import Image from './img';
import Desc from './desc';

function Prd({ data }) {
	if (!data) return <Empty />;

	const disc = data?.discount?.value;
	const dpr = disc ? data?.price * (100 - disc) * 0.01 : data?.price;
	const prop = data?.property.map((el, i) => (
		<p key={i} className="pr-atr">
			{el.name}
		</p>
	));
	return (
		<Main>
			<Helmet>
				<title>{data.name}</title>
				{data.description?.extra && (
					<meta name="description" content={data.description.extra} />
				)}
			</Helmet>
			<div id="product">
				{disc && <p id="pr-disc">-{disc}%</p>}
				<Image img={data?.img[0]} name={data?.name} />
				<p id="pr-name">{data?.name}</p>
				{prop}
				<div id="pr-wrap">
					<p id="pr-value">
						{data?.value} {data?.unit}
					</p>
					<p id="pr-price">
						{disc && <s>{data?.price} ₽</s>}
						{dpr.toFixed(2)} ₽/{data?.unit}
					</p>
				</div>
				<Desc data={data} />
			</div>
		</Main>
	);
}

export default Prd;
