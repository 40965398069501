const data = [
    // {
    //     id: 1,
    //     href: "",
    //     img: {
    //         src: "/img/telegram.png",
    //         alt: "telegram",
    //     }
    // },
    {
        id: 2,
        href: "https://vk.com/grilan",
        img: {
            src: "/img/vk.png",
            alt: "vk",
        }
    },
    // {
    //     id: 3,
    //     href: "",
    //     img: {
    //         src: "/img/whatsapp.png",
    //         alt: "whatsapp",
    //     }
    // },
    {
        id: 4,
        href: "https://www.instagram.com/grilan_company/",
        img: {
            src: "/img/inst.png",
            alt: "inst",
        }
    },
    {
        id: 5,
        href: "https://ok.ru/profile/579105570700",
        img: {
            src: "/img/ok.png",
            alt: "ok",
        }
    },
]

export default data